import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import motto from "../Assets/Images/shapetoIdeas.png";
import "./About.css";
import Aos from "aos";
import Slider from "../ImageSlider/Slider";

const About = () => {
  return (
    <Stack
      id="about"
      className="aboutmain"
      width="100%"
      // border='2px solid red'
    >
      <Stack
        width="100%"
        height="15vh"
        mt={{ xs: 5, sm: 5, md: 10, lg: 10, xl: 10 }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          data-aos="fade-up"
          fontSize={{
            xs: "25px",
            sm: "45px",
            md: "45px",
            lg: "50px",
            xl: "50px",
          }}
          className="whyHackx fontReservationWB"
        >
          WHAT IS HACKX JR?
        </Typography>
      </Stack>
      <Stack
        direction={{
          xs: "column",
          sm: "column",
          md: "row",
          lg: "row",
          xl: "row",
        }}
        // border="2px solid red"
        mt={{ xs: 0, sm: 0, md: 10, lg: 10, xl: 10 }}
        mb={1}
        width="100%"
        // height="50vh"
        justifyContent="space-around"
        alignItems="center"
      >
        <Stack
          data-aos="fade-up-right"
          width={{ xs: "90%", sm: "90%", md: "30%", lg: "30%", xl: "30%" }}
          mb={{ xs: 10, sm: 10, md: 0, lg: 0, xl: 0 }}
          borderRadius={5}
          className="sliderContainer"
        >
          <Slider />
        </Stack>
        <Stack
          width={{ xs: "90%", sm: "90%", md: "45%", lg: "45%", xl: "45%" }}
        >
          <Typography
            // fontSize={{xl: "50px"}}
            data-aos="fade-left"
            data-aos-duration="2000"
            variant="h6"
            color="white"
            className="fontactayWB"
            textAlign={{
              xs: "justify",
              sm: "justify",
              md: "justify",
              lg: "justify",
              xl: "justify",
            }}
            style={{ wordSpacing: "1px" }}
            mt={{ xs: "0", sm: "0", md: "20", lg: "0px", xl: "0" }}
          >
            hackX Jr. 6.0 is an inter-school hackathon organized by the
            Industrial Management Science Students’ Association of the
            Department of Industrial Management, University of Kelaniya.
            <br></br>
            <br></br>
            Backed by the theme “Give Shapes to Ideas”, hackX Jr. proudly
            presents its 6th exultant iteration this year.
          </Typography>

          <Typography
            className="fontactayWB"
            data-aos="fade-right"
            data-aos-duration="2000"
            mt={5}
            variant="h6"
            color="white"
            textAlign="justify"
          >
            The event mainly focuses on providing a suitable platform for the
            bright, young minds of school children to transform their ideas into
            reality. By bridging the gap between industries and the young
            innovators of tomorrow, hackX Jr. strives to spark the creativity
            and innovation among schoolchildren and empowers them to explore the
            IT-based entrepreneurship by giving them a chance
            to showcase their potential and talents.
          </Typography>
        </Stack>
      </Stack>
      <Stack
        data-aos="flip-down"
        justifyContent="center"
        alignItems="center"
        width="100%"
        mt={5}
        mb={5}
      >
        {/* <Typography color="white" fontWeight={900} align="center" variant="h2">"GIVE <span style={{color:"gold"}}>SHAPES</span> TO <span style={{color:"gold"}}>IDEAS</span>"</Typography> */}
        <img className="mottoImage" src={motto} alt="motto" />
      </Stack>
    </Stack>
  );
};

export default About;
