import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import { imageData1, imageData3 } from "../Gallery/ImageArray";
import './sliderimages.css';
import image1 from '../Assets/Images/21.jpg';
import image2 from '../Assets/Images/2.jpg';
import image3 from '../Assets/Images/19.jpg';
import image4 from '../Assets/Images/12.jpg';
import image5 from '../Assets/Images/5.jpg';
import image6 from '../Assets/Images/6.jpg';
import image7 from '../Assets/Images/7.jpg';
import image8 from '../Assets/Images/8.jpg';
import image9 from '../Assets/Images/9.jpg';
import image10 from '../Assets/Images/4.jpg';
import image11 from '../Assets/Images/11.jpg';
import image12 from '../Assets/Images/12.jpg';
import image13 from '../Assets/Images/13.jpg';
import image14 from '../Assets/Images/14.jpg';
import image15 from '../Assets/Images/15.jpg';
import image16 from '../Assets/Images/16.jpg';
import image17 from '../Assets/Images/17.jpg';
import image18 from '../Assets/Images/18.jpg';
import image19 from '../Assets/Images/3.jpg';
import image20 from '../Assets/Images/20.jpg';
import image21 from '../Assets/Images/1.jpg';
import image22 from '../Assets/Images/22.jpg';
import image23 from '../Assets/Images/23.jpg';
import image24 from '../Assets/Images/24.jpg';
import image25 from '../Assets/Images/25.jpg';


// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import img4 from "../Assets/Images"
// import img5 from "../../assets/gallery/5.jpg"
// import img6 from "../../assets/gallery/6.jpg"
// import img7 from "../../assets/gallery/7.jpg"
// import img8 from "../../assets/gallery/8.jpg"
// import img9 from "../../assets/gallery/9.jpg"


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


function Slider() {

    // console.log(imageData1[0].image);
    return (
        <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            transitionDuration={500}
            
            customTransition="all 1s linear"
            arrows={false}
        >
            <div className="imageMargin">
                <img src={image1} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={image2} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={image3} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={image4} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={image14} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={image6} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={image7} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={image8} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={image9} className="imagesslide"/>
            </div>
            {/* <div className="imageMargin">
                <img src={imageData3[9].image} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={imageData3[10].image} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={imageData3[11].image} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={imageData3[12].image} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={imageData3[13].image} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={imageData3[14].image} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={imageData3[15].image} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={imageData3[16].image} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={imageData3[17].image} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={imageData3[18].image} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={imageData3[19].image} className="imagesslide"/>
            </div>
            <div className="imageMargin">
                <img src={imageData3[20].image} className="imagesslide"/>
            </div> */}
            
            
        </Carousel>
    );
}

export default Slider;