const applicationdetails = [
    "All teams are required to register for the competition within the designated registration period, which begins on 1st of July, 2023.",
    "Awareness session will be held on 26th of July 2023 at the University of Kelaniya premises. Additionally the event will be streamed live on our official Facebook page.",
    "Attending the awareness session is highly encouraged.",
    "Students from grade 6 to A/L (2023 A/L students) can participate with a maximum of  5 members per team. (Minimum number of members for a team is one)",
    "No participant is allowed to represent two groups. The inclusion of the same member in two different teams will lead to the automatic disqualification of both teams.",
    "More than one team can participate from one school.",
    "One team can submit more than one proposal but will select only one for the competition.",
    "The idea must be novel and feasible.",
    "Proposal and pitching can be done in either Sinhala or English medium."
    
];

export default applicationdetails;
