import { Stack, Typography } from "@mui/material";
import React from "react";
import "./Home.css";
import { TypeAnimation } from "react-type-animation";
import Typewriter from "typewriter-effect";
import MainTicker from "../Timer/MainTicker";
import mascot from '../Assets/Images/mascot home.png';

const Home = () => {
  return (
    <Stack
      width="100%"
      height={{ xs: "90vh", sm: "92vh", md: "80vh", lg: "80vh", xl: "80vh" }}
      className="home"
      // border="2px solid blue"
      justifyContent="center"
    >
      <Stack
      mt={{ xs:15, sm: 15, md: 22, lg: 22, xl: 22 }}
        width="100%"
        direction={{
          xs: "column-reverse",
          sm: "column-reverse",
          md: "row",
          lg: "row",
          xl: "row",
        }}
        // alignItems="center"
        // justifyContent="center"
        // border="2px solid yellow"
      >
        <Stack
          width={{ xs: "100%", sm: "100%", md: "55%", lg: "55%", xl: "55%" }}
          // border="2px solid red"
          direction="row"
          // gap={5}
          alignItems="center"
          
        >
          <Stack width="0"></Stack>
          <Stack width="100%" ml={{xs:"0px",sm:"0",md:"0",lg:"60px",xl:"60px"}}  direction="column" justifyContent="center" >
            <Typography  paddingLeft={{xs:"0px",sm:"0",md:"0",lg:"8px",xl:"8px"}}  className="herohackathonmsg fontactayR" fontSize={{xs:"18px",sm:"30px",md:"25px",lg:"30px",xl:"30px"}} textAlign={{xs:"center",sm:"center",md:"left",lg:"left",xl:"left"}}>INTER-SCHOOL HACKATHON 6.0</Typography>
            <Stack width="100%" height={{
                xs: "60px",
                sm: "100px",
                md: "100px",
                lg: "105px",
                xl: "105px",
              }} textAlign={{xs:"center",sm:"center",md:"left",lg:"left",xl:"left"}}
              ml={{xs:"5px",sm:"0",md:"0",lg:"0",xl:"0"}}
              paddingLeft={{xs:"0px",sm:"0",md:"0",lg:"8px",xl:"8px"}}
              // textOverflow="hidden"
              // border="2px solid yellow"
              >
              <Typewriter
                //   class="heroMainMsg"
                options={{
                  strings: [
                    "<span class='heroMainMsg fontReservationWB' style='color: #60FFCC;'>COMING </span> <span class='heroMainMsg fontReservationWB' style='color: #ffffff;'>SOON!</span>",
                    "<span class='heroMainMsg fontReservationWB' style='color: #60FFCC;'>COMING </span> <span class='heroMainMsg fontReservationWB' style='color: #ffffff;'>SOON!</span>",
                  ],
                  autoStart: true,
                  loop: true,
                  cursor: "",
                }}
              />
            </Stack>
            <Stack>
              <Typography paddingLeft={{xs:"0px",sm:"0",md:"0",lg:"10px",xl:"10px"}} className="fontactayWB" fontSize={{xs:"18px",sm:"30px",md:"25px",lg:"30px",xl:"30px"}} textAlign={{xs:"center",sm:"center",md:"left",lg:"left",xl:"left"}} color="#60FFCC">Registrations open in</Typography>
            </Stack>
            <Stack  ml={{xs:"30px",sm:"0px",md:"0",lg:"0",xl:"0"}} direction="row" alignItems="center"
            justifyContent={{xs:"center",sm:"center",md:"center",lg:"left",xl:"left"}}
            // border="2px solid yellow"
            >
              <MainTicker />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          width={{ xs: "100%", sm: "100%", md: "40%", lg: "40%", xl: "40%" }}
          pr={{xs: "30px", sm: "30px", md: "0", lg: "0", xl: "0"}}
          mb={{xs: "0px", sm: "0px", md: "0", lg: "0", xl: "0"}}
          // border="2px solid green"
          // alignItems="center"
          justifyContent="center"
        >
          {/* <div className="heroImage"> */}
            <img src={mascot} className="heroMascot" alt="mascotHome" />
          {/* </div> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Home;
