import { Stack,Typography } from '@mui/material'
import React from 'react'
import './Gallary.css';
// import SwipeSlider from '../SwipeSlider/SwipeSlider';
import Slider from '../GallarySlider/Slider';

const Gallary = () => {
  return (
    <Stack id="gallery" width="100%" className='gallaryImageContainer' direction="column" alignItems="center"
    //  border="2px solid red"
     >
        <Typography className='fontReservationWB' data-aos="fade-up" data-aos-duration="2000" fontSize={{xs:"25px",sm:"45px",md:"45px",lg:"50px",xl:"50px"}} color="white"  mt={10} mb={3}>GLIMPSE OF HACKX JR 5.0 </Typography>
        <Stack width="90%" mb={{xs:10,sm:10,md:0,lg:10,xl:10}}>
            {/* <SwipeSlider/> */}
            <Slider/>
        </Stack>
    </Stack>
  )
}

export default Gallary