// import style from './TickerCell.module.scss';
import './TickerCell.css';

export const TickerCell = ({ label, value }) => {
    const formattedValue = value < 10 ? `0${value}`: value.toString();

    return (
        <div className="tickerCell" >
            <span className="tickerCellValue">{ formattedValue }</span>
            <span className="tickerCellLabel ">{ label }</span>
        </div>
    );
}