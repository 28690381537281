import { Stack } from "@mui/material";
import "./App.css";
import "aos/dist/aos.css";
import Aos from "aos";
import { useEffect,useState } from "react";
import About from "./Components/AboutContainer/About";
import Home from "./Components/HomeContainer/Home";
import Slider from "./Components/ImageSlider/Slider";
import Navbar from "./Components/Navbar";
import Gallary from "./Components/Gallary/Gallary";
import SimpleSlider from "./Components/Gallery/SimpleSlider";
import Footer from "./Components/Footer/Footer";
import Design from "./Components/Design";
import IntroText from "./Components/IntroText";
import TimelineItems from "./Components/Timeline/TimelineItems";
import Competitionspecs from "./Components/CompetitionSpecifications/Competitionspecs";

// import Navbar from './Components/NavBar/Navbar';
// import Navbars from './Components/Navbar/Navbars'

function App() {


  const [introVisibility, setintroVisibility] = useState(true);

  useEffect(() => {
    Aos.init();
    Aos.refresh();
    console.log("aos ");
   
  }, []);

  const timeSleep = setTimeout(() => {
    setintroVisibility(false);
    console.log("came here");
  }, 5000);


  return (
    <div className="App">
      
        {/* <Navbar/> */}
        {introVisibility ? (
          <>
            <Design />
            <IntroText/>
          </>
        ) : (
          <>
          <Design/>
          <Navbar />
          <Home />
          <About />
          <TimelineItems/>
          <Competitionspecs/>
          {/* <Stack
            width="100%"
            justifyContent="center"
          >
          <SimpleSlider/>
          </Stack> */}
          <Gallary/>
          <Footer/>
          </>
        )}
    
    </div>
  );
}

export default App;
