import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './sliderimages.css';
import img1 from "../Assets/Images/10.jpg"
import img2 from "../Assets/Images/4.jpg"
import img3 from "../Assets/Images/22.jpg"
import img4 from '../Assets/Images/group1.jpg'

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import img4 from "../Assets/Images"
// import img5 from "../../assets/gallery/5.jpg"
// import img6 from "../../assets/gallery/6.jpg"
// import img7 from "../../assets/gallery/7.jpg"
// import img8 from "../../assets/gallery/8.jpg"
// import img9 from "../../assets/gallery/9.jpg"


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


function Slider() {
    return (
        <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            transitionDuration={500}
            customTransition="all 1s linear"
            arrows={false}
        >
            <div>
                <img src={img1} className="imagesslide"/>
            </div>
            <div>
                <img src={img2} className="imagesslide"/>
            </div>
            <div>
                <img src={img3} className="imagesslide"/>
            </div>
            <div>
                <img src={img4} className="imagesslide"/>
            </div>
        </Carousel>
    );
}

export default Slider;