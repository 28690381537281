import React from 'react'
import { useRef,useState,useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import './NavbarStyles.css';
import { NavLink } from "react-router-dom";
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import jrlogo from '../Components/Assets/Images/jrWebLogo.png';
import fblogo from '../Components/Assets/Images/fbIcon.png';
import mailLogo from '../Components/Assets/Images/envelope.png';
import callLogo from '../Components/Assets/Images/call.png';
import { Stack } from '@mui/material';


export default function Navbar() {
  const navRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const updatePosition = () => {
        setScrollPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
}, []);

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};
  return (
    
    <header style={scrollPosition > 0 ? { "borderBottom": "#60FFCC 2px solid" } : null}>
      <Stack 
      // border="2px solid red" 
      width="120px">
      <Link to="#">
        <img className='jrlogo' src={jrlogo} alt='logoImage'/>
      </Link>
      </Stack>
      <Stack 
      // border="2px solid yellow"
       width={{xs:"7%",sm:"7%",md:"7%",lg:"7%",xl:"7%"}}>

      </Stack>
      <Stack 
      // border="2px solid green" 
      width={{lg:"80%",xl:"80%"}} direction="row" alignItems="center" justifyContent="center">
        <nav ref={navRef}>
        <button
              // className="nav-btn nav-close-btn"
              style={{display:"none"}}
              onClick={showNavbar}>
              <FaTimes />
				</button>
          
            <Link className='fontReservationWB' to="#" onClick={showNavbar} smooth>HOME</Link>
            <Link className='fontReservationWB' to="#about" onClick={showNavbar} smooth>ABOUT</Link>
            <Link className='fontReservationWB' to="#timeline" onClick={showNavbar} smooth>EVENT FLOW</Link>
            
            <Link className='fontReservationWB' to="#compdetails" onClick={showNavbar} smooth>PROCESS</Link>
            <Link className='fontReservationWB' to="#gallery" onClick={showNavbar} smooth>GALLERY</Link>
            <Link className='fontReservationWB' to="#contact" onClick={showNavbar} smooth>CONTACT</Link>
          <button
              className="nav-btn nav-close-btn "
              onClick={showNavbar}>
              <FaTimes />
				</button>
        </nav>
        </Stack>
        <Stack width={{xs:"15%",sm:"15%",md:"15%",lg:"15%",xl:"12%"}} 
        // border="2px solid red"
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        >
        <Stack className='socialMedias' display={{xs:"none",sm:"none",md:"none",lg:"flex",xl:"flex"}} direction="row"
        gap={5} 
        // border="2px solid yellow"
        // marginLeft={20}
        // style={{position:"relative"}} 
        // left={{xm:"270px",sm:"0px",md:"265px",lg:"265px",xl:"265px"}}
        >
          <a href='https://www.facebook.com/hackXJunior' target='_blank'><img src={fblogo} style={{width:"25px"}} alt='fblogo'/></a>
          <a onClick={() => window.location = 'mailto:hackxjr.mit@gmail.com'}><img src={mailLogo} style={{width:"25px",height:"30px"}} alt='fblogo'/></a>
          <a href="tel:+94112903282" target='_blank'><img src={callLogo} style={{width:"25px",height:"26px",marginTop:"0px"}} alt='fblogo'/></a>
        </Stack>
        </Stack>
        

        <Stack 
        // border="2px solid yellow"
        >
        <button
          className="nav-btn"
          onClick={showNavbar}>
          <FaBars />
			</button>
      </Stack>
    </header> 
  )
}
