import { Stack, Typography } from "@mui/material";
import React from "react";

import "./Timeline.css";
import timelineData from "../Utils/TimelineData";

const TimelineItems = () => {
  return (
    <Stack  id="timeline" mt={0} direction="column" alignItems="center" justifyContent="center" 
    // border="2px solid red"
    >
      <Typography
        className="fontReservationWB"
        textAlign="center"
        data-aos="fade-up"
        data-aos-duration="1800"
        fontSize={{
          xs: "25px",
          sm: "45px",
          md: "45px",
          lg: "50px",
          xl: "50px",
        }}
        color="white"
        mt={10}
        mb={10}
      >
        EVENT FLOW
      </Typography>
      <Stack  direction="column" alignItems="center" justifyContent="center" width={{ xs: "100%", sm: "90%", md: "100%", lg: "100%", xl: "100%" }} sx={{ zIndex: "10" }}>
        {/* <section className="timeline-section"> */}
        <div className="timeline-items">
          {timelineData.map((itemdata) => (
            <div
              data-aos={itemdata.animationType}
              data-aos-duration="3000"
              className="timeline-item"
            >
              <div className="timeline-dot"></div>
              {/* <div className="timeline-date">2015</div> */}
              {/* <div className="timeline-content"> */}
              <Stack
                className="timeline-content typeoneglassmorphism"
                height="100%"
              >
                <Stack direction="row" height="100%">
                  <Stack
                    className="typetwoglassmorphism"
                    // width="30%"
                    width={{
                      xs: "20%",
                      sm: "20%",
                      md: "30%",
                      lg: "30%",
                      xl: "30%",
                    }}
                    // height="130px"
                    height={{
                      xs: "100%",
                      sm: "70px",
                      md: "130px",
                      lg: "130px",
                      xl: "130px",
                    }}
                    // height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Stack width="50%">
                      <img className="contentImage" src={itemdata.image}></img>
                    </Stack>
                  </Stack>
                  <Stack
                    // width="70%"
                    width={{
                      xs: "80%",
                      sm: "80%",
                      md: "70%",
                      lg: "70%",
                      xl: "70%",
                    }}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Typography
                      color="white"
                      fontWeight="500"
                      variant="h5"
                      // mb={2}
                      textAlign="center"
                      fontSize={{
                        xs: "20px",
                        sm: "20px",
                        md: "25px",
                        lg: "25px",
                        xl: "25px",
                      }}
                      className="fontactayWB"
                    >
                      {itemdata.titleName}
                    </Typography>
                    <Typography
                      className="fontactayCT"
                      color="white"
                      fontWeight="400"
                      variant="h6"
                      textAlign="center"
                      fontSize={{
                        xs: "17px",
                        sm: "17px",
                        md: "22px",
                        lg: "22px",
                        xl: "22px",
                      }}
                    >
                      {/* {itemdata.date} */}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              {/* </div> */}
            </div>
          ))}

          {/* <div data-aos="fade-left" className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">2016</div>
            <div className="timeline-content">
              <h3>timeline item title</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p>
            </div>
          </div>
          <div data-aos="fade-right" className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">2017</div>
            <div className="timeline-content">
              <h3>timeline item title</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p>
            </div>
          </div>
          <div data-aos="fade-left" className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">2018</div>
            <div className="timeline-content">
              <h3>timeline item title</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p>
            </div>
          </div>
          <div data-aos="fade-right" className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">2019</div>
            <div className="timeline-content">
              <h3>timeline item title</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p>
            </div>
          </div>
          <div data-aos="fade-left" className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">2020</div>
            <div className="timeline-content">
              <h3>timeline item title</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p>
            </div>
          </div> */}
          {/* <div data-aos="fade-right" className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">2021</div>
            <div className="timeline-content">
              <h3>timeline item title</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p>
            </div>
          </div> */}
        </div>
        {/* </section> */}
      </Stack>
    </Stack>
  );
};

export default TimelineItems;
