import { Box, Stack, Typography } from "@mui/material";
import imssalogo from "../Assets/Images/imssalogo.png";
import uniLogo from "../Assets/Images/uoklogo.png";
import FacebookIcon from "../Assets/Images/fbIcon1.png";
import mailIcon from "../Assets/Images/envelope.png";
import callIcon from "../Assets/Images/call.png";
// import jrlogo from '../Assets/Images/jr';
import youtubeLogo from "../Assets/Images/youtubeIcon.png";
import fblogo from "../Assets/Images/fbIcon.png";
import instergramLogo from "../Assets/Images/instergramIcon.png";
import "./Footer.css";

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Stack direction="column" id="contact">
      <Stack
        borderTop="2px solid #60FFCC"
        zIndex={10}
        className="footer"
        direction={{
          xs: "column",
          sm: "column",
          md: "row",
          lg: "row",
          xl: "row",
        }}
        // border="2px solid purple"
        width="100%"
        // alignItems="center"
        justifyContent="space-around"
        fontFamily="Poppins"
        p={5}
      >
        <Stack
          direction="column"
          // border="2px solid purple"
          alignItems="center"
          height="70%"
          width={{ xs: "100%", sm: "100%", md: "30%", lg: "40%", xl: "40%" }}
          pt={2}
          className="fontactayR"
        >
          {/* <Typography align="center" variant="h6" fontWeight={600} mb={2}>
          GENERAL INFORMATION
          </Typography> */}
          <Typography className="fontactayR" variant="body1" align="center">
            Industrial Management Science Students’ Association
          </Typography>
          <Typography variant="body1" align="center">
            Department of Industrial Management,
            <br></br>
            University of Kelaniya, Sri Lanka
          </Typography>
          <Box mt={1}>
            <img className="footerUniLogo" src={imssalogo} alt="IMSSA logo" />
            <img
              className="footerUniLogo"
              src={uniLogo}
              alt="university Logo"
            />
          </Box>
        </Stack>

        {/* <Stack
          direction="column"
          border="2px solid purple"
          alignItems="center"
          width={{ xs: "100%", sm: "100%", md: "40%", lg: "40%", xl: "40%" }}
          height="70%"
        >
          <Typography variant="h5" fontWeight={600} align="center" mb={2}>
            GENERAL INFORMATION
          </Typography>
          <Typography variant="h6" align="center">
            Industrial Management Science Students’ Association.
          </Typography>
          <Typography variant="h6" align="center">
            Department of Industrial Management, University of Kelaniya,
            Dalugama
          </Typography>
        </Stack> */}

        <Stack
          direction={{
            xs: "column-reverse",
            sm: "column-reverse",
            md: "column",
            lg: "column",
            xl: "column",
          }}
          mt={{ xs: "20px", sm: "20px", md: "20px", lg: "0px", xl: "0px" }}
          // border="2px solid purple"
          alignItems="center"
          height="70%"
          width={{ xs: "100%", sm: "100%", md: "30%", lg: "30%", xl: "30%" }}
        >
          {/* <Typography variant="h6" align="center" fontWeight={600} mt={1} mb={2}>

            CONTACT US
          </Typography> */}

          <Stack
            width="90%"
            direction="column"
            // border="2px solid red"
            alignItems="center"
            justifyContent="center"
            display={{xs:"none",sm:"none",md:"none",lg:"flex",xl:"flex"}}
            mt={2}
          >
            <a style={{width:"71%",marginBottom:"10px"}} className="clickablebtn" href="https://www.facebook.com/hackXJunior" target="_blank">
            <Stack direction="row"  
            // border="2px solid red"
            >
            <img
                src={fblogo}
                style={{ width: "25px", marginLeft: "0px",marginRight:"12px" }}
                alt="fblogo"
              />
              <Typography
                  
                  color="white"
                  variant="body1"
                >
                  {" "}
                  www.facebook.com/hackXJunior
                </Typography>
            </Stack></a>




            <a  className="clickablebtn" href="tel:+94112903282" style={{ textDecoration: "none",width:"71%",marginBottom:"10px" }}>
              <Stack direction="row"  
              // border="2px solid red"
              >
                <img
                  style={{ width: "25px", height: "26px", marginTop: "0px",marginRight:"12px" }}
                  src={callIcon}
                ></img>
                <Typography
                  // className="clickablebtn"
                  color="white"
                  variant="body1"
                >
                  {" "}
                  +94 71 897 6568
                </Typography>
              </Stack>
            </a>


            <Stack className="clickablebtn" direction="row" width="71%" 
            // border="2px solid red"
            onClick={() => (window.location = "mailto:hackxjr.mit@gmail.com")}
            >
            <img
                  style={{ width: "25px", height: "26px", marginTop: "0px",marginRight:"12px" }}
                  src={mailIcon}
                ></img>
                <Typography
                // onClick={() => (window.location = "mailto:hackxjr.mit@gmail.com")}
                  // className="clickablebtn"
                  color="white"
                  variant="body1"
                >
                  {" "}
                  hackxjr.mit@gmail.com
                </Typography>
            </Stack>
          </Stack>

          <Box
            width="40%"
            display={{xs:"flex",sm:"flex",md:"flex",lg:"none",xl:"none"}}
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            mt={{ xs: "13px", sm: "13px", md: "10px", lg: "14px", xl: "14px" }}
            mb={2}
            // border="2px solid red"
          >
            <a href="https://www.facebook.com/hackXJunior" target="_blank">
              <img
                src={fblogo}
                style={{ width: "25px", marginLeft: "0px" }}
                alt="fblogo"
              />
            </a>
            {/* <a
              href="https://www.instagram.com/hackx_uok/?utm_medium=copy_link"
              target="_blank"
            >
              <img
                src={instergramLogo}
                style={{ width: "26px", marginLeft: "0px" }}
                alt="fblogo"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCih4TF8YK9AaUo1MJI6NG_Q"
              target="_blank"
            >
              <img
                src={youtubeLogo}
                style={{ width: "26px",height:"32px",marginTop:"6px", marginLeft: "0px" }}
                alt="fblogo"
              />
            </a> */}
            {/* <FacebookIcon  /> */}
            {/* <InstagramIcon/> */}
            {/* <LinkedInIcon/> */}
            {/* <a href='https://www.facebook.com/hackXJunior' target='_blank' ></a>
            <a href='https://www.instagram.com/hackx_uok/?utm_medium=copy_link' ><InstagramIcon/></a>
            <a href='https://www.linkedin.com/company/imssauok/'></a> */}
          </Box>
          <Stack direction="column" alignItems="center" justifyContent="center"
          display={{xs:"flex",sm:"flex",md:"flex",lg:"none",xl:"none"}}
          >
            <a href="tel:+94112903282" style={{ textDecoration: "none" }}>
              <Typography
                className="clickablebtn"
                color="white"
                variant="body1"
              >
                Phone : +94 71 897 6568
              </Typography>
            </a>
            <Typography
              className="clickablebtn"
              onClick={() => (window.location = "mailto:hackxjr.mit@gmail.com")}
              variant="body1"
            >
              hackxjr.mit@gmail.com
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ backgroundColor: "black" }} zIndex={10}>
        <Link to="/userlogin">
          <Typography
            variant="body1"
            color="#868991"
            textAlign="center"
            // sx={{ backgroundColor: "black" }}
            zIndex={10}
            pb={1}
          >
            hackX Jr 2023 - All Rights Reserved.
          </Typography>
        </Link>
      </Stack>
    </Stack>
  );
};

export default Footer;
