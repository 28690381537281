import React from "react";
import { Typography, Stack, Button } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import applicationdetails from "../Utils/ApplicationProgressDetails";
import mascotonboard from '../Assets/Images/mascot with hand board-Recovered.png'
import './CompetitionSpecs.css';

const Competitionspecs = () => {
  return (
    <Stack id="compdetails" className="aboutmain" width="100%"
    // border="2px solid red"
    >
      <Stack
        width="100%"
        height="15vh"
        mt={7}
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          data-aos="fade-up"
          fontSize={{
            xs: "25px",
            sm: "45px",
            md: "45px",
            lg: "50px",
            xl: "50px",
          }}
          className="whyHackx fontReservationWB"
        >
          COMPETITION DETAILS
        </Typography>
      </Stack>
      <Stack
        direction={{
          xs: "column-reverse",
          sm: "column-reverse",
          md: "row-reverse",
          lg: "row-reverse",
          xl: "row-reverse",
        }}
        // border="2px solid red"
        mt={5}
        // mb={10}
        width="100%"
        // height="50vh"
        justifyContent="space-around"
        alignItems="center"
      >
        <Stack
          data-aos="fade-up-right"
          // height="10vh"
          width={{ xs: "90%", sm: "90%", md: "40%", lg: "40%", xl: "40%" }}
          // mb={{ xs: 10, sm: 10, md: 0, lg: 0, xl: 0 }}
          alignItems="center"
          justifyContent="center"
          // border="2px solid green"
          // borderRadius={5}
        >
            <img className="mascotwithimage" src={mascotonboard}></img>
        </Stack>
        <Stack
          width={{ xs: "95%", sm: "95%", md: "55%", lg: "55%", xl: "55%" }}
          marginLeft={{ xs: "0", sm: "0", md: "20px", lg: "50px", xl: "50px" }}
          className="maincontainer"
          data-aos="zoom-out-up" data-aos-duration="2000"
          // border="2px solid green"
        >
          <Stack  width="100%" direction="column" alignItems="center" justifyContent="center"
          // border="2px solid red"
          >
          {applicationdetails.map((item) => (
            <Stack width="100%" mb={3}  direction="row"
            // border="2px solid green"
            alignItems="center"
            justifyContent="center"
            >
              <Stack width={{ xs: "10%", sm: "10%", md: "5%", lg: "5%", xl: "5%" }} 
              // border="2px solid red" 
              >
                <CreateIcon style={{ color: "#60FFCC" }} />
              </Stack>
              <Stack width="90%" 
              // border="2px solid purple"
              >
                <Typography variant="h6" color="white" className="fontactayWB">
                  {item}
                </Typography>
              </Stack>
            </Stack>
          ))}
          {/* <Stack width={{ xs: "100%", sm: "100%", md: "50%", lg: "50%", xl: "50%" }} direction="column" alignItems="center" justifyContent="center">
          <button style={{width:"50%"}} className="detailbtn">More Details</button>
          </Stack> */}
          
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Competitionspecs;
