import imageone from "../Assets/Images/online-registration.png";
import awareness from '../Assets/Images/people.png';
import proposalsub from '../Assets/Images/agreement.png';
import semifinalsicon from '../Assets/Images/advertising.png';
import workshop from '../Assets/Images/workshop.png';
import finals from '../Assets/Images/trophy.png';
import competitive from '../Assets/Images/competitive.png';
// import proposalsub from '../Assets/Images/agreement.png';
const timelineData = [
  {
    titleName: "Registration Period",
    // date: "July 01 - July 28",
    animationType: "fade-right",
    image: imageone,
  },

  {
    titleName: "Awareness Session",
    // date: "July 26",
    animationType: "fade-left",
    image: awareness,
  },
  {
    titleName: "Proposal Submission Period",
    // date: "July 30 - August 13",
    animationType: "fade-right",
    image: proposalsub,
  },
  {
    titleName: "Semi-finals",
    // date: "September 10",
    animationType: "fade-left",
    image: competitive,
  },
  {
    titleName: "hackX Jr. 2023 Grand Finals",
    // date: "September 27",
    animationType: "fade-right",
    image:finals,
  },
];

export default timelineData;
