import { useState } from 'react';
import { add } from 'date-fns';
import {Ticker} from './Ticker/Ticker';

// import style from './App.module.scss';
import './mainTimer.css';

// const futureDate = add(new Date(), {
//     days: 6,
//     hours: 22,
//     minutes: 40
//   });


const futureDate=new Date(2023,6,5,23,59,0,0);  


  

const MainTicker = () => {

    console.log(futureDate);
    const [tickerVisible, setTickerVisible] = useState(true);
    const tickerEl = tickerVisible ? <Ticker futureDate={futureDate} /> : null;
    const toggleText = tickerVisible ? 'Hide Countdown': 'Show Countdown';
  
    return (
      <div className="pageContainer fontactayR" >
        { tickerEl }
      </div>  
    );
}

export default MainTicker