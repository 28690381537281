import React from 'react'
import {motion} from 'framer-motion';
import contentlogo from '../Components/Assets/Images/contentLogo_34.png';

const IntroText = () => {
  return (
    <div className='introContainer'>
      <div className="introWrapper">
        <motion.div initial={{y:-20}} animate={{y:1}} transition={{duration:1}} className="textIntro">
          "Give <span className="introSpan"> Shapes </span> To{" "}
          <span className="introSpan"> Ideas </span>"
        </motion.div>
      </div>

      <motion.img initial={{opacity:0}} animate={{opacity:100}} transition={{duration:2}} className='contentLogo' src={contentlogo} alt='content logo'/>
    </div>
  );
}

export default IntroText